import { _differentRiskLocation } from "../../pages/6_Kundendaten/Kundendaten";

// returns true if data is missing or invalid
const validateData = (name: string, value: any) => {
    const fromSessionStorage = JSON.parse(sessionStorage.getItem('globalState') as string);

    console.log("validate " + name);
    //check empty text fields
    if (name === "wallettype" ||
        name === "country" ||
        name === "sumInsured" ||
        name === "xpub" ||
        name === "signature" ||
        name === "prename" ||
        name === "name" ||
        name === "birthdate" ||
        name === "email" ||
        name === "street" ||
        name === "housenumber" ||
        name === "zipcode" ||
        name === "city" ||
        name === "pincode") {
        if (isEmpty(value)) {
            return true;
        }
    }

    //check checkboxes
    if (name === "applySubAccount" ||
        name === "applyAnalyzing" ||
        name === "applyLegalBitcoins" ||
        name === "applyPermanentlyInhabited" ||
        name === "applyDSGVO" ||
        name === "applyInitDocuments" ||
        name === "applyDocuments" ||
        name === "applyProof" ||
        name === "applyEmailNotSecure" ||
        name === "applyNoConsulting") {
        if (!value) {
            return true;
        }
    }

    //check wallet sepcifiv checkboxes
    if(fromSessionStorage.wallettype === 'bitbox') {
        if (name === "applyBitboxOnlySegwit") {
            if (!value) {
                return true;
            }
        }
    }

    //check type specific
    if (name === "email") {
        if (!validateEmail(value)) {
            return true;
        }
    } else if (name === "zipcode") {
        if (!validateGermanZIP(value)) {
            return true;
        }
    } else if (name === "pincode") {
        if (!validatePIN(value)) {
            return true;
        }
    } else if (name === "name") {
        if (value.length > 200) {  //element 
            return true;
        }
    } else if (name === "prename" ||
        name === "city" ||
        name === "street") {
        if (value.length > 70) {  //element 
            return true;
        }
    } else if (name === "housenumber") {
        if (value.length > 15) {  //element 
            return true;
        }
    } else if (name === "birthdate") {
        const age = getAge(value); //element: Age of customer should be between 18 and 99 years
        if(age < 18 || age > 99) {
            return true;
        } 
    } else if (name === "xpub") {
        if(fromSessionStorage.xpubAPIError) {
            return true;
        }
    } else if (name === "signature") {
        if(fromSessionStorage.signatureAPIError) {
            return true;
        }
    } else if (name === "pincode") {
        if(fromSessionStorage.pincodeAPIError) {
            return true;
        }
    }

    if(_differentRiskLocation && !_differentRiskLocation.checked) {
        //check empty text fields
        if (name === "insuredObjectStreet" ||
            name === "insuredObjectHousenumber" ||
            name === "insuredObjectZipcode" ||
            name === "insuredObjectCity") {
            if (isEmpty(value)) {
                return true;
            }
        }

        //check type specific
        if (name === "insuredObjectZipcode") {
            if (!validateGermanZIP(value)) {
                return true;
            }
        }
        if (name === "insuredObjectStreet" ||
            name === "insuredObjectCity") {
            if (value.length > 70) {  //element 
                return true;
            }
        } else if (name === "insuredObjectHousenumber") {
            if (value.length > 15) {  //element 
                return true;
            }
        }
    }
    return false;
}

export const validateElement = (target: HTMLFormElement, value?: any) => {
    console.log("validate target", target);
    return validateData(target.name, value != null ? value : target.value);
};

export const validateFinal = () => {
    //TODO validate time special
    //console.log("validate Final");

    const fromSessionStorage = JSON.parse(sessionStorage.getItem('globalState') as string);

    //console.log("fromSessionStorage", fromSessionStorage);
    if (validateData('wallettype', fromSessionStorage.wallettype)) {
        return 1;
    }
    if (validateData('country', fromSessionStorage.country)) {
        return 2;
    }
    if (validateData('sumInsured', fromSessionStorage.sumInsured)) {
        return 3;
    }
    if (validateData('xpub', fromSessionStorage.xpub)) {
        return 4;
    }
    if (validateData('applyBitboxOnlySegwit', fromSessionStorage.applyBitboxOnlySegwit)) {
        return 4;
    }
    if (validateData('applySubAccount', fromSessionStorage.applySubAccount)) {
        return 4;
    }
    if (validateData('applyAnalyzing', fromSessionStorage.applyAnalyzing)) {
        return 4;
    }
    if(fromSessionStorage.xpubAPIError) {
        return 4;
    }
    if (validateData('signature', fromSessionStorage.signature)) {
        return 5;
    }
    if(fromSessionStorage.signatureAPIError) {
        return 5;
    }
    if (validateData('applyLegalBitcoins', fromSessionStorage.applyLegalBitcoins)) {
        return 5;
    }
    if (validateData('prename', fromSessionStorage.prename)) {
        return 6;
    }
    if (validateData('name', fromSessionStorage.name)) {
        return 6;
    }
    if (validateData('birthdate', fromSessionStorage.birthdate)) {
        return 6;
    }
    if (validateData('email', fromSessionStorage.email)) {
        return 6;
    }
    if (validateData('street', fromSessionStorage.street)) {
        return 6;
    }
    if (validateData('housenumber', fromSessionStorage.housenumber)) {
        return 6;
    }
    if (validateData('zipcode', fromSessionStorage.zipcode)) {
        return 6;
    }
    if (validateData('city', fromSessionStorage.city)) {
        return 6;
    }
    if (validateData('insuredObjectStreet', fromSessionStorage.insuredObjectStreet)) {
        return 6;
    }
    if (validateData('insuredObjectHousenumber', fromSessionStorage.insuredObjectHousenumber)) {
        return 6;
    }
    if (validateData('insuredObjectZipcode', fromSessionStorage.insuredObjectZipcode)) {
        return 6;
    }
    if (validateData('insuredObjectCity', fromSessionStorage.insuredObjectCity)) {
        return 6;
    }
    if (validateData('applyPermanentlyInhabited', fromSessionStorage.applyPermanentlyInhabited)) {
        return 6;
    }
    if (validateData('applyDSGVO', fromSessionStorage.applyDSGVO)) {
        return 6;
    }
    if (validateData('applyInitDocuments', fromSessionStorage.applyInitDocuments)) {
        return 7;
    }
    if (validateData('applyDocuments', fromSessionStorage.applyDocuments)) {
        return 7;
    }
    if (validateData('applyProof', fromSessionStorage.applyProof)) {
        return 7;
    }
    if (validateData('applyEmailNotSecure', fromSessionStorage.applyEmailNotSecure)) {
        return 7;
    }
    if (validateData('applyNoConsulting', fromSessionStorage.applyNoConsulting)) {
        return 7;
    }
    if (validateData('pincode', fromSessionStorage.pincode)) {
        return 7;
    }

    return 0;
};

const validateEmail = (email: string) => {
    const isEmail = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    const isNotToLong = email.length <= 60; //element
    return isEmail && isNotToLong;
};

const validateGermanZIP = (zip: string) => {
    return String(zip)
        .toLowerCase()
        .match(
            /\b\d{5}\b/g
        );
};

const validatePIN = (pin: string) => {
    return String(pin)
        .toLowerCase()
        .match(
            /\b\d{4}\b/g
        );
};

const isEmpty = (v: any) => {
    return v === undefined || v === 0 || String(v).trim().length === 0;
}

const getAge = (dateString: string) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
